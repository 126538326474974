<template>

    <div  class="container H_100vh bg_fff">
        <div class="flex_row_between PLR_3 PTB_4">
            <van-icon name="arrow-left" @click="$router.go(-1)" size=".35rem" />
        </div>
        <div class="PLR_5 textL PB_4 PT_2 fontS_17 font_weight_bold">
            请选择所要添加的技能类型
        </div>
        <div class="flex_rowC PLR_5 PB_4 relative">
            <input   v-model.lazy="txt"  type="text" style="width:50%" class="PTB_2 B_1_F44C06 PL_4 B_radius_5 flex_grow_1" placeholder="请输入内容">
            <van-icon @click="search1" name="search" size=".4rem" class="absolute color_F44C06" style="right: 10%" />
        </div>
       
    
      <div v-if="filterList.length == 0">
        <div class="D_flex color_686868">
            <van-sidebar  v-model="activeKey">
              <van-sidebar-item @click="viewList(item)" v-for="(item, index) in list" :key="index" :title="item.position_name" />
            </van-sidebar> 
            
            <div style="height:9rem;" class="rel H_100vh overflow_y flex_grow_1 W_60 flex_rows_start PT_1">
              <div @click="getName(index,item1.positionId)" :class="item1.status?'txtColor':' ' " class="W_50 textL PLR_2 PTB_3" v-for="(item1,index) in list1" :key="index">
                  {{item1.position_name}}
              </div> 
            </div> 
            
            
        </div>
      </div>
      <div  class="list" v-else>
        <div :class="item1.status?'active':'noactive' " @click="searchMsg(item1,index)"  v-for="(item1,index) in filterList" :key="index">{{item1.position_name}}</div>
      </div> 

      
   </div>
</template>
<script>
import '@/assets/css/common.css'; 
import { Toast } from "vant";
export default {
  data(){
    return {
      activeKey:0,
      list:[],//1级列表
      list1:[],//2级列表
      name:"",
      skill_id:"",
      filterList:[], //搜索列表
      obj:{},
      txt:"",
      type:""
       
    }
  },
  methods:{
    search1(){
      this.type = 1
      this.$http.post("/user/v1/Position/positionListSearch",{reqType:"positionListSearch",name:this.txt})
      .then((res)=>{
        res = JSON.parse(res.data)
        if(res.data.length>0){
          res.data.map((item)=>{
          item.status = false
        })
        this.filterList = res.data
        // console.log(res)
        }else{
          Toast("暂无数据")
        }
        
      })
      .catch((e)=>{
        console.log(e)
      })
    },
    add1(){
      let id = sessionStorage.getItem("id")
      let obj = this.obj
      // console.log(id, obj)
      this.$http.post("/user/v1/Position/userSkill",{reqType:"userSkill",user_id:id,skill_type:obj.type,skill_name:obj.skill_name,mim_id:0,status:1,skill_id:obj.skill_id})
        .then((res)=>{ 
          res = JSON.parse(res.data)
        if(res.code == 0){
          Toast.success("添加成功")
          this.filterList = []
          setTimeout(()=>{
             this.$router.push("./onlineTag")
          },1000)
         
        }
        })
        .catch((e)=>{console.log(e)})
    },
    searchMsg(item,index){
      // console.log(item,index)
        this.filterList.map((item,index1)=>{
        if(index == index1){
          item.status = true
        }else{
          item.status = false
        }
      })
      let user_id = localStorage.getItem("id")
      this.$http.post("/user/v1/Position/userSkill",{reqType:"userSkill",user_id:user_id,skill_type:item.type,skill_name:item.position_name,mim_id:0,status:2,skill_id:item.positionId})
        .then((res)=>{ 
          res = JSON.parse(res.data)
          // console.log(res)
        if(res.code == 0){
          Toast.success("添加成功")
          
          setTimeout(()=>{
            this.filterList = []
             this.$router.push("./onlineTag")
          },1000)
         
        }
        })
        .catch((e)=>{console.log(e)})
    
      
    },
    text(e){
      let data = e.data
      let allList = this.allList
      // console.log(allList,e)
      let arr = []
     for(var i=0;i<allList.length;i++){
       if(allList[i].children){
        for(var j=0;j<allList[i].children.length;j++){
         if(allList[i].children[j].position_name.indexOf(data) >= 0) {
           arr.push(allList[i].children[j])
         }
       }
       }
       
     }
     arr.map((val)=>{
       val.status = false
       return val
     })
     console.log(arr)
     this.filterList = arr
     
    },
    viewList(item){
      this.$http.post("/user/v1/Position/positionList",{reqType:"positionList",pid:item.positionId,})
      .then((res)=>{
        res = JSON.parse(res.data)
        // console.log(res)
        this.list1 = res.data
      })
      .catch((e)=>{
        console.log(e)
      })
    },
    getName(index1,id){
     
     
     this.list1.map((item,index)=>{
       if(index == index1){
         item.status = true
       }else{
          item.status = false
       }
     })
     let list1 = JSON.stringify(this.list1)
    this.$router.push({path:"./addTagItem1",query:{id:id,list1:list1}})

    },
   
  },
  mounted(){
    //获取职位列表
    let id = this.$route.query.id
    this.$http.post("/user/v1/Position/positionList",{reqType:"positionList",pid:id})
    .then((res)=>{
        let res1 = JSON.parse(res.data)
        // console.log(res1)
       if(res1.code == 0){
        this.list = res1.data
        let id = res1.data[0].positionId
        this.$http.post("/user/v1/Position/positionList",{reqType:"positionList",pid:id})
        .then((res)=>{
          res = JSON.parse(res.data)
          res.data.map((item)=>{
            item.status = false
          })
          // console.log(res.data)

          this.list1 = res.data
          
        })
        .catch((e)=>{console.log(e)})
        // console.log(id)
       }else{
         Toast(res.msg)
       }
    })
    .catch((e)=>{console.log(e)})
    
  }
}
</script>
<style scoped>
.container1{
  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
  }
.container{
  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
}
 
.van-sidebar{
height:10rem;
overflow:hidden;
 overflow-y:scroll;
}
.rel{
  position: relative;
}
.next{
  position: absolute;
  bottom: 1.5rem;
  left:1rem;
  width: 4.01rem;
  height: 0.97rem;
  line-height: 0.97rem;
  background: linear-gradient(90deg, #FF4800 0%, #FC9E46 100%);
  border-radius: 0.16rem;
  text-align: center;

  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
}
.txtColor{
  color:#FF4800
}
.list{
    display:flex;
    flex-wrap: wrap;
    margin-top:0.46rem;
    margin-left:0.25rem;
   

}
.noactive{
height: 0.7rem;
line-height: 0.7rem;
background: #FFFFFF;
border-radius: 0.4rem;
border: 0.01rem solid #979797;
padding:0 0.5rem;
font-size: 0.28rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #979797;
margin-right:0.3rem;
margin-bottom:0.2rem;
}
.addtel{
  position:absolute;
  bottom:1rem;
  left:1.7rem;
  width: 4.01rem;
  height: 0.97rem;
  line-height: 0.97rem;
  background: linear-gradient(90deg, #FF4800 0%, #FC9E46 100%);
  border-radius: 0.16rem;
  text-align: center;

  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
}
.active{
  height: 0.7rem;
line-height: 0.7rem;
border-radius: 0.4rem;
border: 0.01rem solid #FF4800;
padding:0 0.5rem;
font-size: 0.28rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FF4800;
margin-right:0.3rem;
margin-bottom:0.2rem;
}


</style>